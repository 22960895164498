import React, { useRef} from "react"

import Map from './Map';
import { promiseToFlyTo } from 'lib/map';
import L from 'leaflet';
import { Marker, Popup } from 'react-leaflet';
import MyMarker from './extended_marker'


// import { Actionbutton } from './common'


const LOCATION = {
    lat: 40.693027,
    lng: -73.982204,
  };

  const AdressLocation = {
    lat: 40.693027,
    lng: -73.982204,
  };
  const DEFAULT_ZOOM = 2;
  const ZOOM = 12;
  
  const timeToZoom = 2000;
  const timeToOpenPopupAfterZoom = 2000;
//   const timeToUpdatePopupAfterZoom = timeToOpenPopupAfterZoom + 3000;
  
//   const popupContentHello = `<p>Hello 👋</p>`;
  const popupContentHello = `
      <div>
        <h1 style='margin:0; margin-top:2rem; margin-bottom: 1rem; padding:0; font-weight: 700; text-rendering:optimizeLegibility; font-size:3rem; line-height:1; color:#333;'>Feodoroff Law</h1>
        <p style="text-align:center; margin-bottom:2rem; color: #888;">147 Prince Street, Brooklyn, N.Y. 11201</p>
      </div>
  `;

 
   

const MapComponent = (props) => {

    const markerRef = useRef();
    const CENTER = [props.center.lat, props.center.lng];


    async function mapEffect({ leafletElement } = {}) {
        if ( !leafletElement ) return;
    
        const popup = L.popup({
          maxWidth: 800,
        });
    
        const setLocation = () =>{
          // return {lat: 38.9072, lng: -77.0369}
          return props.AdressLocation[0]
        }
  
      
        const location = await setLocation()
      //   const location = await getCurrentLocation().catch(() => LOCATION );
    
        const { current = {} } = markerRef || {};
        const { leafletElement: marker } = current;
  
        marker.setLatLng( location );
        popup.setLatLng( location );
        
        if(props.sign){
          popup.setContent( popupContentHello );
        }
    
        setTimeout( async () => {
          await promiseToFlyTo( leafletElement, {
            // zoom: ZOOM,
            zoom: props.zoom,
            center: props.center,
          });
  
          if(props.sign){
            marker.bindPopup( popup );
            setTimeout(() => marker.openPopup(), timeToOpenPopupAfterZoom );
          }else{
            marker.closePopup()
          }
  
          // setTimeout(() => marker.setPopupContent( popupContentGatsby ), timeToUpdatePopupAfterZoom );
        }, timeToZoom );
      }

    const mapSettings = {
        center: CENTER,
        defaultBaseMap: 'OpenStreetMap',
        // zoom: DEFAULT_ZOOM,
        zoom: props.defaultZoom,
        mapEffect,
      };

      return(
        <Map {...mapSettings}>
            <Marker ref={markerRef} position={[props.AdressLocation[0].lat, props.AdressLocation[0].lng]} />
            {props.AdressLocation.length > 1 ? (
                <>
                    <MyMarker onClick={()=>props.setLocation(2)} index={2} selected={props.selected} position={[props.AdressLocation[1].lat, props.AdressLocation[1].lng]}>
                      {props.sign ? (
                          <Popup>
                            <h2 style={{color:'black'}}>{props.messages[0]}</h2>
                          </Popup>
                      ):<></>}
                    </MyMarker>
                    <MyMarker onClick={()=>props.setLocation(3)} index={3} selected={props.selected} position={[props.AdressLocation[2].lat, props.AdressLocation[2].lng]}>
                      {props.sign ? (
                          <Popup>
                            <h2 style={{color:'black'}}>{props.messages[1]}</h2>
                          </Popup>
                      ):<></>}
                    </MyMarker>

                </>
                    ) : 
                <>
                </>
            }
        </Map>
      )
}

export default MapComponent
