import React, {useState} from "react"
// import React, { useRef } from 'react';
import styled from "@emotion/styled"
import { css } from "@emotion/core"
// import { Link } from "gatsby"
import { Waypoint } from 'react-waypoint';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown} from '@fortawesome/free-solid-svg-icons'
import { BiMap } from 'react-icons/bi';
import { FiPhoneCall } from 'react-icons/fi';

import MapComponent from './map_component';
import { Actionbutton, ActionbuttonInverted } from './common'



const Bg = styled.div`
   width: 100%;
   height: 600px;
   color: white;
   min-height: 540px;
   position: relative;

   @media (max-width: 720px) {
        height: 1240px;
    }

`


const ContRight = styled.div`
    width: 50%;
    height: 100%;
    float: left;
    background-color: #0099df;
    overflow: hidden;
                                   


    h1{
        color: black;
    }
    @media (max-width: 720px) {
        width: 100%;
        height: 600px;
    }

    @media (max-width: 720px) {
        width: 0%;
        height: 0%;                           
    }
                          
`

const ContLeft = styled.div`
    width: 50%;
    height: 100%;
    float: left;
    background-color: #0099df;
    position: relative;

    @media (max-width: 720px) {
        height: 300px;
        width: 100%;
    }
    
`

const MiniContainer = styled.div`
    width: 100%;
    height: 100%;
    min-height: 540px;
    

    margin-left: 0%;
    overflow: auto;
    animation-duration: 1s;
    transition: 1s;
    margin-top: 0%;
    position: relative;
    z-index: 1;

    
    @keyframes slideMov {
        0%   {margin-left: 100%;}
        100% {margin-left: 0%;}
    }

    
`

const MiniContainer2 = styled.div`
    animation-duration: 1s;
    background-color: white;
    width: 100%;
    height: 110%;
    min-height: 540px;
    margin-bottom: 0%;
    position: relative;
    z-index: 1;
    overflow: auto;
`

const TextContainer = styled.div`
    color: white;
    width: 70%;
    margin-left: 15%;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    @media (max-width: 720px) {
        top: 50%;                               
    }
    
    @media (max-width: 920px) {
        width: 80%;
        margin-left: 10%;
    }

    span, p{
        font-size: 1.6rem;
        @media (max-width: 400px) {
            font-size: 1.4rem;                                 
        }
     }   
     
     h1{
        @media (max-width: 600px) {
          font-size: 2.6rem;
        }
        @media (max-width: 400px) {
            font-size: 2.4rem;
          }
     }   
`
const IconLContainer = styled.div`
    float: left;
    margin-right: 2rem;
    display: block;
`

// const IconSContainer = styled.div`
//     float: left;
//     margin-right: 2rem;
//     display: none;
    
//     @media (max-width: 1007px) {
//         display: block;
//     }
// `

const FormButton = Actionbutton.withComponent('input')

// form{
//     -webkit-appearance: none;
//     appearance: none;
// }
const FormContainer = styled.div`
    width: 95%;
    max-width: 95%;
    color: gray;

    position: absolute;
    top: 55%;
    left: 51.25%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    

    @media (max-width: 600px) {
        top: 50%;
    }

    span{
        color: red;
    }
    input{
        outline: 0;
        border: 1px solid #214c9b;
        border-top-style: none;
        border-right-style: none;
        border-left-style: none;
        border-bottom-style: solid;
        font-size: 1.4rem;
    }

    label, select{
        color: gray;
        outline: 0;
        font-size: 1.3rem;
    }

`



let animationName = ''
let marginCont = '0%'
// let marginCont = '0%'
            
let buttonLabel = (<span style={{fontSize:'1.3rem'}}>Location &nbsp;<FontAwesomeIcon  style={{cursor:'pointer', color:'white'}} icon={faChevronUp}/></span>)
let marginVert = '-660px'
// let marginVert = '-110%'
let formObject = {}

const ContactBox = props =>(
    <>
        <div style={{height:'3rem'}}/>
        <div id='contact'/>
        <TextContainer>
            {props.perdiem ? (
                <>
                    <h1 style={{display:'inline-block', marginTop: '0', textAlign:'left'}}>Contact Us For a Quote</h1>
                    <p style={{textAlign:'left', marginBottom: '2rem'}}>We provide flat fees based on the complexity of the matter involved. Please fill out this form and provide your contact information. We will contact you within no more than two business days with a quote. Should this matter need expedited handling, call our main office line below.</p>
                </>
                    ) : 
                <>
                     <h1 style={{display:'inline-block', marginTop: '0', textAlign:'left'}}>Would you like to have a talk?</h1>
                     <p style={{textAlign:'left', marginBottom: '2rem'}}>We are ready to listen to you at anytime</p>
                </>
            }
           
            <div style={{}}>
                <IconLContainer><BiMap size={30} style={{color:'#214c9b'}}/></IconLContainer><p style={{textAlign:'left'}}> 147 Prince Street, Brooklyn, N.Y. 11201, United States</p>
                <div style={{marginBottom: '3rem'}}/>
                <IconLContainer><FiPhoneCall size={30} style={{color:'#214c9b'}}/></IconLContainer><span>(718)-715-1667</span>
            </div>
            <ActionbuttonInverted css={css`
                display: block;
                @media (max-width: 720px) {
                    display: none;                                           
                }
                `} style={{marginTop:'5rem'}} onClick={props.changeLayer}>{buttonLabel}</ActionbuttonInverted>
            
        </TextContainer>
    </>
) 


const ContactSec = (props) => {
    // const markerRef = useRef();
    // const anotherRef = useRef();

    const [rendering, setRendering] = useState(true);
    const [Layer, setLayer] = useState(1);
  
    /**
     * mapEffect
     * @description Fires a callback once the page renders
     * @example Here this is and example of being used to zoom in and set a popup on load
     */
    const _handleEnter = () =>{
        // animationName = 'slideMov'
        marginCont = '0%'
        setTimeout(() => setRendering(false), 700 );
        
    }

    const changeLayer= (evt) =>{
        // console.log('clicked!')
        if(Layer === 0){
            buttonLabel = (<span style={{fontSize:'1.3rem' }}>Location &nbsp;<FontAwesomeIcon  style={{cursor:'pointer', color:'white'}} icon={faChevronUp}/></span>)            
            // marginVert = '-160%'
            marginVert = '-660px'
            setLayer(1)
        }else{
            marginVert = '0px'
            buttonLabel = (<span style={{fontSize:'1.3rem' }}>Send Message &nbsp;<FontAwesomeIcon  style={{cursor:'pointer', color:'white'}} icon={faChevronDown}/></span>)            
            setLayer(0)
        }   
    }

    const handleSubmit = async event => {
        await event.preventDefault()
        // event.persist()
        // console.log('form object: ', formObject)
        await fetch(`https://feodorofflaw-api.herokuapp.com/api/v1/form_submit`,
        {method: "POST", mode: "cors", cache: "no-cache", credentials: "omit", headers: {"Content-Type": "application/json"}, redirect: "follow", referrer: "client", body: JSON.stringify({"formObject": formObject})})
        .then(res => res.json())
        .then(json => {
            console.log('message: ', json.message)    
        });

        formObject = {}
        alert('Message sent successfully!')
      }
    
      const updateField = (evt) =>{
        formObject[evt.target.name] = evt.target.value
      }

    return (
        <Waypoint onEnter={_handleEnter}>             
            <Bg>
                <ContLeft>
                    <ContactBox perdiem={props.perdiem} changeLayer={changeLayer}/>
                </ContLeft>

                <ContRight>
                    <MiniContainer style={{marginLeft: marginCont, animationName: animationName, marginTop:marginVert}}>
                        <MapComponent zoom={12} defaultZoom={2} center={{'lat': 40.693027, 'lng':-73.982204}} sign={true} AdressLocation={[{'lat': 40.693027, 'lng': -73.982204}]}/>
                    </MiniContainer>
                    <MiniContainer2 style={{marginLeft: marginCont, animationName: animationName}}>
                        <FormContainer>
                            <form name={"contact"} method="post" css={css`
                                -webkit-appearance: none;
                                appearance: none;
                                `} onSubmit={event => {
                                 event.target.reset();
                                (async () => { 
                                    await handleSubmit(event)
                                }) ();
                                
                                }}>
                                <div style={{float:'left', width:'50%', overflow:'auto'}}>
                                    <label htmlFor="IDLname">Last name<span>*</span></label>
                                    <br></br>
                                    <input onChange={updateField} type="text" id="IDLname" name="lastname" style={{width:'95%', maxWidth:'350px', minWidth:'100px'}}/>
                                </div>
                                <div style={{float:'left', width:'50%', overflow:'auto'}}>
                                    <label htmlFor="IDFname">First name<span>*</span></label>
                                    <br></br>
                                    <input onChange={updateField} type="text" id="IDFname" name="firstname" style={{width:'95%', maxWidth:'350px', minWidth:'100px'}}/>
                                </div>
                                <div style={{marginTop:'2rem', float:'left', width:'50%', overflow:'auto'}}>
                                    <label htmlFor="IDemail">Email<span>*</span></label>
                                    <br></br>
                                    <input onChange={updateField} type="text" id="IDemail" name="email" style={{width:'95%', maxWidth:'350px', minWidth:'100px'}}/>
                                </div>
                                <div style={{marginTop:'2rem', float:'left', width:'50%', overflow:'auto'}}>
                                    <label htmlFor="IDtel">Tel<span>*</span></label>
                                    <br></br>
                                    <input onChange={updateField} type="text" id="IDtel" name="tel" style={{width:'95%', maxWidth:'350px', minWidth:'100px'}}/>                               
                                </div>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <label>Are you an attorney?<span>*</span></label>
                                <input onChange={updateField} style={{marginLeft:'0.5rem'}} type="radio" id="yesAttorney" name="attorney" value="yes"/>&nbsp;&nbsp;
                                <label htmlFor="yesAttorney">Yes</label>
                                <input onChange={updateField} style={{marginLeft:'1rem', marginBottom:'1rem', marginTop:'1rem'}} type="radio" id="NoAttorney" name="attorney" value="no"/>&nbsp;&nbsp;
                                <label htmlFor="NoAttorney">No</label>
                                <br></br>
                                <br></br>

                                <label htmlFor="jobDescription">If you are, please choose a description</label>&nbsp;&nbsp;&nbsp;
                                <select onChange={updateField} style={{width:'max-content'}} id="jobDescription" name="descriptions">
                                    <option value="No Selected">Select an Option</option>
                                    <option value="Motions to Reopen">Motions to Reopen</option>
                                    <option value="Legal Briefs">Legal Briefs</option>
                                    <option value="Immigration Court Representation">Immigration Court Representation</option>
                                    <option value="Interview Representation">Interview Representation</option>
                                </select>
                                <br></br>
                                <br></br>
                                <label htmlFor="message">Your message<span>*</span></label>
                                <br></br>
                                <textarea onChange={updateField} name="message" style={{fontSize:'1.4rem', marginTop:'0.5rem', width:'98%', height:'10rem', resize:'none', border:'1px solid #214c9b', outline:'0'}}/>
                                <br></br>                                
                                <br></br>                                
                                <FormButton style={{border:'2px solid #0099df', margin: 'auto' }} type="submit" value="Submit"/>
                            </form>
                        </FormContainer>
                    </MiniContainer2>
                </ContRight>
                <div css={css`
                            width: 0%;
                            height: 0%;                              
                            
                            @media (max-width: 720px) {
                                width: 100%;
                                height: 600px;                             
                            }
                            `}>
                    <MiniContainer2 style={{marginLeft: '0', height: '520px', marginTop: '0'}}>
                        <FormContainer>
                            <form name={"contact"} method="post" css={css`
                                -webkit-appearance: none;
                                appearance: none;
                                `} onSubmit={event => {
                                event.target.reset();
                                (async () => { 
                                    await handleSubmit(event)
                                }) ();
                                
                                }}>
                                <div style={{float:'left', width:'50%', overflow:'auto'}}>
                                    <label htmlFor="IDLname">Last name<span>*</span></label>
                                    <br></br>
                                    <input onChange={updateField} type="text" id="IDLname" name="lastname" style={{width:'95%', maxWidth:'350px', minWidth:'100px'}}/>
                                </div>
                                <div style={{float:'left', width:'50%', overflow:'auto'}}>
                                    <label htmlFor="IDFname">First name<span>*</span></label>
                                    <br></br>
                                    <input onChange={updateField} type="text" id="IDFname" name="firstname" style={{width:'95%', maxWidth:'350px', minWidth:'100px'}}/>
                                </div>
                                <div style={{marginTop:'2rem', float:'left', width:'50%', overflow:'auto'}}>
                                    <label htmlFor="IDemail">Email<span>*</span></label>
                                    <br></br>
                                    <input onChange={updateField} type="text" id="IDemail" name="email" style={{width:'95%', maxWidth:'350px', minWidth:'100px'}}/>
                                </div>
                                <div style={{marginTop:'2rem', float:'left', width:'50%', overflow:'auto'}}>
                                    <label htmlFor="IDtel">Tel<span>*</span></label>
                                    <br></br>
                                    <input onChange={updateField} type="text" id="IDtel" name="tel" style={{width:'95%', maxWidth:'350px', minWidth:'100px'}}/>                               
                                </div>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <label>Are you an attorney?<span>*</span></label>
                                <input onChange={updateField} style={{marginLeft:'0.5rem'}} type="radio" id="yesAttorney" name="attorney" value="yes"/>&nbsp;&nbsp;
                                <label htmlFor="yesAttorney">Yes</label>
                                <input onChange={updateField} style={{marginLeft:'1rem', marginBottom:'1rem', marginTop:'1rem'}} type="radio" id="NoAttorney" name="attorney" value="no"/>&nbsp;&nbsp;
                                <label htmlFor="NoAttorney">No</label>
                                <br></br>
                                <br></br>

                                <label htmlFor="jobDescription">If you are, please choose a description</label>&nbsp;&nbsp;&nbsp;
                                <select onChange={updateField} style={{width:'max-content'}} id="jobDescription" name="descriptions">
                                    <option value="No Selected">Select an Option</option>
                                    <option value="Motions to Reopen">Motions to Reopen</option>
                                    <option value="Legal Briefs">Legal Briefs</option>
                                    <option value="Immigration Court Representation">Immigration Court Representation</option>
                                    <option value="Interview Representation">Interview Representation</option>
                                </select>
                                <br></br>
                                <br></br>
                                <label htmlFor="message">Your message<span>*</span></label>
                                <br></br>
                                <textarea onChange={updateField} name="message" style={{fontSize:'1.4rem', marginTop:'0.5rem', width:'97%', height:'10rem', resize:'none', border:'1px solid #214c9b', outline:'0'}}/>
                                <br></br>                                
                                <br></br>                                
                                <FormButton style={{border:'2px solid #0099df', marginRight: '3%',float:'right' }} type="submit" value="Submit"/>
                            </form>
                        </FormContainer>
                    </MiniContainer2>
                    <MiniContainer style={{height:'420px' , minHeight: '420px', marginLeft: '0', marginTop: '0'}}>
                        <MapComponent zoom={12} defaultZoom={2} center={{'lat': 40.693027, 'lng':-73.982204}} sign={true} AdressLocation={[{'lat': 40.693027, 'lng': -73.982204}]}/>
                    </MiniContainer>
                </div>
            </Bg> 
        </Waypoint>     
          
      
    );
  };
  

        
export default ContactSec
